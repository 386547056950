import React from 'react';
import Layout from '../layouts/en.jsx';
import { ListEn } from '@components/pages/pieces';
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

const PiecesPageEnglish = () => {
  const {wpPage, allWpPiece}  = useStaticQuery(graphql`
  query PiecesEnglish {
    allWpPiece(filter: {link: {regex: "/^(?!(en\/|\/en\/))/i"}}) {
      nodes{
        slug
        featuredImage{
          node{
            sourceUrl
            altText
          }
        }
        title
        id
        date
        fieldsPieces{
          place
          date
          tecnica
          type
          medidas
          category
          imagenes {
            variables {
              video
              vendido
              nftLink
            }
            gallery {
              sourceUrl
              link
            }
          }
          backgroundMobile{
            sourceUrl
            altText
          }
        }
      }
    }
    wpPage(slug: {eq: "pieces"}) {
      id
      date
      title
      slug
      status
      featuredImage {
        node {
          sourceUrl
        }
      }
      language {
        slug
        name
        locale
        id
        homeUrl
        code
      }
      seo {
        canonical
        metaDesc
        opengraphDescription
        opengraphImage {
          altText
          link
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphSiteName
        opengraphPublisher
        opengraphTitle
        opengraphType
        opengraphUrl
        schema {
          raw
        }
        title
        twitterDescription
        twitterImage {
          altText
          link
        }
        twitterTitle
      }
    }
  }
      `);
  const { fieldsHome, seo } = wpPage;
  return (
    <>
      <Seo seo={seo}
           author={false}
           lang={wpPage.language.locale}
           thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}
           pathname="/en/pieces"/>
      <Layout pageId="pieces">
        <div className="container">
          <ListEn key={allWpPiece.nodes.id} data={allWpPiece} />
        </div>
      </Layout>
    </>
  );
};

export default PiecesPageEnglish;
